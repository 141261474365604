import React, { useEffect, useState, useRef } from "react";
import '../scss/pages/EduMemeManagement.scss';
import axios from "axios";
import { SERVER } from "../config/config";
import { createPath, Navigate, useNavigate, useParams } from 'react-router-dom';
import useAuth from '../useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEdit, faPen, faPlus, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../hooks/Navbar';
import { PacmanLoader } from 'react-spinners';

const EduMemeManagement = () => {
    const {user, loading} = useAuth();
    const navigate = useNavigate();
    const { lessonID, edumemeID } = useParams();
    const [edumeme, setEdumeme] = useState();
    const [navbarVisible, setNavbarVisible] = useState(false);
    const [navbarClosing, setNavbarClosing] = useState(false);
    const [pageTitle, setPageTitle] = useState('أضف معلومة');
    const [option, setOption] = useState('Info');
    const [optionContent, setOptionContent] = useState('picture');
    const maxChars = 400;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const [lesson, setLesson] = useState();
    const [lessons, setLessons] = useState();
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [description, setDescription] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [imagePerc, setImagePerc] = useState(0);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [videoPreview, setVideoPreview] = useState('');
    const [videoPerc, setVideoPerc] = useState(0);
    const [inputs, setInputs] = useState({});
    const [link, setLink] = useState('');

    const openNavbar = () => {
        setNavbarClosing(false);
        setNavbarVisible(true);
    };

    const closeNavbar = () => {
        setNavbarClosing(true);
        setTimeout(() => {
            setNavbarVisible(false);
        }, 500);
    };

    const changeOption = (id) => {
        if (option === 'Info' && id === 'meme') {
            setOption('Meme');
            setPageTitle('أضف ميم');
        } else if (option === 'Meme' && id === 'info') {
            setOption('Info');
            setPageTitle('أضف معلومة');
        }
    };

    const changeOptionContent = (id) => {
        if (optionContent === 'picture' && id === 'video') {
            setOptionContent('video');
        } else if (optionContent === 'video' && id === 'picture') {
            setOptionContent('picture');
        }
    };

    const handleImageClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleVideoClick = () => {
        document.getElementById('videoInput').click();
    };

    useEffect(() =>{
        const fetchLesson = async () =>{
            try {
                const response = await axios.get(`${SERVER}/lessons/getLessonByID`, {
                    params: {
                        lessonID: lessonID,
                    }
                });
                setLesson(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        fetchLesson()
    }, [lessonID])

    useEffect(() =>{
        const fetchLessons = async() =>{
            try {
                const id = lesson.chapter_ID
                const response = await axios.get(`${SERVER}/lessons/getLessons`, {
                    params: {
                        chapterID: id,
                    }
                });
                setLessons(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        if (lesson){
            fetchLessons()
        }
    },[lesson])

    useEffect(() => {
        if (edumemeID) {
            const fetchEduMeme = async () => {
                try {
                    const response = await axios.get(`${SERVER}/edumemes/getEdumeme`, {
                        params: {
                            edumemeID : edumemeID
                        }
                    });
                    setEdumeme(response.data)
                    setTitle(response.data.title);
                    setSubtitle(response.data.subtitle);
                    setDescription(response.data.text);
                    setImagePreview(response.data.image);
                    setVideoPreview(response.data.video);
                    setLink(link);
                } catch (error) {
                    console.log(error);
                }
            };

            fetchEduMeme();
            setPageTitle('تعديل ميم');
        }
    }, [edumemeID]);

    useEffect(() => {
        if (selectedImage) {
            setImagePreview(URL.createObjectURL(selectedImage));
        }
    }, [selectedImage]);

    useEffect(() => {
        if (selectedVideo) {
            setVideoPreview(URL.createObjectURL(selectedVideo));
        }
    }, [selectedVideo]);

    const resetFields = () => {
        setTitle('');
        setSubtitle('');
        setDescription('');

        setSelectedImage(null);
        setImagePreview(null);
        setImagePerc(0);

        setSelectedVideo(null);
        setVideoPreview(null);
        setVideoPerc(0);

        setLink('');
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleLessonChange = (e) => {
        const selectedLesson = e.target.getAttribute('data-value');
        setLesson(lessons.filter((lesson) => lesson.ID === parseInt(selectedLesson))[0]);
        setDropdownVisible(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData()
            formData.append('title', title)
            formData.append('subtitle',subtitle)
            formData.append('dataType', optionContent === 'video' ? 'video' : 'text/image')
            formData.append('type', optionContent === 'video' ? 1 : 0)
            formData.append('text', description)
            formData.append('media', selectedImage ? selectedImage : selectedVideo)
            formData.append('link', link)
            formData.append('lesson_id', lesson.ID)

            if (edumemeID) {
                formData.append('edumemeID', edumemeID)
                await axios.put(`${SERVER}/edumemes/editEdumeme/`, formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } else {
                await axios.post(`${SERVER}/edumemes/addEdumeme`, formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }

            resetFields();
            navigate(`/lessons/${lesson.chapter_ID}/${lesson.ID}`);
        } catch (error) {
            console.log(error);
        }
    };

    if (loading) {
        return <div style={{minHeight:'100vh', width:'100%', display: 'flex', justifyContent:'center', alignItems: 'center'}}><PacmanLoader color='#2478DC' /></div>
    }

    if (!user) {
        return <Navigate to="/authentication/login" />;
    }else{
        return (
            <div className="edumeme-management">
                <div className="navigation">
                    <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
                </div>
                {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
    
                <h1> {pageTitle} </h1>
                <div className="choice">
                    <div id='info' className={`option ${option === 'Info' ? 'active' : ''}`} onClick={(e) => changeOption(e.target.id)}> معلومة </div>
                    <div id='meme' className={`option ${option === 'Meme' ? 'active' : ''}`} onClick={(e) => changeOption(e.target.id)}> ميم </div>
                </div>
    
                <form>
                    <div className="main">
                        <div className="textual">
                            <div>
                                <label> الدرس </label>
                                <div className="lessons" onClick={toggleDropdown}>
                                    <span> {lesson ? lesson.title : 'الدرس'} </span>
                                    <button onClick={toggleDropdown}><FontAwesomeIcon icon={faChevronDown} /></button>
                                    {dropdownVisible && (
                                        <div ref={dropdownRef} className="dropdown">
                                            {lessons && lessons.map((lesson, index) => (
                                                <div
                                                    key={index}
                                                    data-value={lesson.ID}
                                                    onClick={handleLessonChange}
                                                    className="dropdown-item"
                                                >
                                                    {lesson.title}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <label> عنوان </label>
                                <input
                                    type="text"
                                    placeholder="عنوان"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
    
                                <label> عنوان الفرعي </label>
                                <input
                                    type="text"
                                    placeholder="عنوان الفرعي"
                                    value={subtitle}
                                    onChange={(e) => setSubtitle(e.target.value)}
                                />
    
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="media">
                            <label>المحتوى</label>
                            <div className="choice-media">
                                <div id='picture' className={`option ${optionContent === 'picture' ? 'active' : ''}`} onClick={(e) => changeOptionContent(e.target.id)}> نص/صورة </div>
                                <div id='video' className={`option ${optionContent === 'video' ? 'active' : ''}`} onClick={(e) => changeOptionContent(e.target.id)}> فيديو </div>
                            </div>
    
                            {optionContent === 'picture' ? (
                                <>
                                    <label> النص </label>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <textarea
                                            placeholder="النص"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            maxLength={maxChars}
                                        />
                                        <div className="char-count">{description ? (maxChars - description.length) + '/' + maxChars : '0/' + maxChars}</div>
                                    </div>
                                    <label>الصورة {imagePerc > 0 && imagePerc + '%'}</label>
                                    <div className="image-container">
                                        <div>
                                            <input
                                                type="file"
                                                id="fileInput"
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                onChange={(e) => setSelectedImage(e.target.files[0])}
                                            />
                                            {imagePreview ? (
                                                <img
                                                    src={imagePreview}
                                                    alt="Uploaded"
                                                    id="image-uploaded"
                                                    onClick={handleImageClick}
                                                />
                                            ) : (
                                                <button id='image-input' type="button" onClick={handleImageClick}>تحميل</button>
                                            )}
                                        </div>
                                    </div>
                                    <label> إضافة رابط (إختياري) </label>
                                    <input
                                        type="text"
                                        placeholder="إضافة رابط"
                                        value={link}
                                        onChange={(e) => setLink(e.target.value)}
                                    />
                                </>
                            ) : (
                                <>
                                    <label> الفيديو </label>
                                    <div className="video-container">
                                        <div>
                                            <input
                                                type="file"
                                                id="videoInput"
                                                style={{ display: 'none' }}
                                                accept="video/*"
                                                onChange={(e) => setSelectedVideo(e.target.files[0])}
                                            />
                                            {videoPreview ? (
                                                <video
                                                    src={videoPreview}
                                                    controls
                                                    id="video-uploaded"
                                                    onClick={handleVideoClick}
                                                />
                                            ) : (
                                                <button id='video-input' type="button" onClick={handleVideoClick}>تحميل</button>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
    
                        </div>
                    </div>
                    <div className="buttons">
                        <button id="submit-button" type="submit" onClick={handleSubmit}> حفظ </button>
                        <button
                            id="reset-button"
                            type="button"
                            onClick={resetFields}
                        > رجوع </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default EduMemeManagement;
