import React, { useEffect, useState } from 'react';
import '../../scss/pages/Home/ResetPassword.scss';
import axios from 'axios';
import { SERVER } from '../../config/config';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import logo from '../../utils/logowhite.png'
import mrKrab from '../../utils/krabs.png'
import { PacmanLoader } from 'react-spinners';

const ResetPassword = () => {
    const { resetToken } = useParams();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [timeRemaining, setTimeRemaining] = useState(0);
    const [tokenStatus, setTokenStatus] = useState(null);

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const response = await axios.post(`${SERVER}/auth/verifyPasswordToken`, { token: resetToken });
                if (response.status === 200) {
                    setTokenStatus(true);
                    const timeRemaining = response.data.minutes * 60 + response.data.seconds; // Calculate total time remaining in seconds
                    
                    // Set the time remaining
                    if (timeRemaining > 0) {
                        setTimeRemaining(timeRemaining); // Set time if valid
                    } else {
                        console.error('Invalid time remaining:', timeRemaining);
                        setTimeRemaining(0); // Default to 0 if invalid
                    }
                } else {
                    setTokenStatus(false);
                }
            } catch (err) {
                console.log(err);
                setTokenStatus(false);
            }
        };        

        if (resetToken) {
            verifyToken();
        }
    }, [resetToken]);

    useEffect(() => {
        let timer;
        if (timeRemaining > 0) {
            timer = setInterval(() => {
                setTimeRemaining(prev => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        setTokenStatus(false);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer); // Clear interval on unmount
    }, [timeRemaining]);

    const confirmPassword = async () => {
        try {
            if (newPassword === confirmNewPassword) {
                const response = await axios.post(`${SERVER}/auth/reset-password/${resetToken}`, { newPassword });
                if (response.status === 200) {
                    navigate('/authentication/login');
                }
            } else {
                console.log('Password does not match');
            }
        } catch (err) {
            console.log(err);
        }
    }

    if (tokenStatus === null) {
        return <div style={{minHeight:'100vh', width:'100%', display: 'flex', justifyContent:'center', alignItems: 'center'}}><PacmanLoader color='#2478DC' /></div>
    }else if(tokenStatus === false){
        return <Navigate to='/TokenNotValid' />
    }else {
        return (
            <div className="ResetPassword">
                <div className='password-form'>
                    <h1> إعادة تعيين كلمة المرور </h1>
                    <p className='remaining-time'> الوقت المتبقي: {Math.floor(timeRemaining / 60)} دقيقة و {timeRemaining % 60} ثانية </p>
                    <form>
                        <label>كلمة المرور</label>
                        <div className="password-input">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder='كلمة المرور'
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                        <label> تأكيد كلمة المرور</label>
                        <div className="password-input">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder='تأكيد كلمة المرور'
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                            />
                        </div>
                        <div id='checkbox'>
                            <input
                                type="checkbox"
                                id="showPassword"
                                onChange={() => setShowPassword(!showPassword)}
                            />
                            <label htmlFor="showPassword">إظهار كلمة المرور</label>
                        </div>
                    </form>
                    <button onClick={confirmPassword}> تغيير كلمة المرور  </button>
                </div>
                <div className="design">
                    <div className='logo'>
                        <img src={logo} alt="Logo" />
                        <h2>الميم و التعليم</h2>
                    </div>
                    <img src={mrKrab} alt="Canny" />
                    <h3> إذا كنت تنسى كلمة المرور كثيرا, ننصحك بتسجيلها
                    و حفظها في مكان آمن </h3>
                </div>
            </div>
        )
    }
}

export default ResetPassword;
