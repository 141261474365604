import HomeNavbar from '../../hooks/HomeNavbar';
import '../../scss/pages/Home/Home.scss'
import logo from '../../utils/logo1.png'; 
import intro from '../../utils/intro.png'
import creation from '../../utils/edumemesCreation.png'
import mobile from '../../utils/mobile.png'
import desktop from '../../utils/desktop.png'
import ground from '../../utils/ground.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok, faYoutube, faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';
import meme1 from '../../utils/main_page/image5.png'
import meme2 from '../../utils/main_page/image6.png'
import { useNavigate } from 'react-router-dom';
import Footer from '../../hooks/Footer';

const Home = () =>{
    const navigate = useNavigate();
    return(
      <div className='Home'>
          <HomeNavbar />
          <div className='intro'>
              <img src={logo} />
              <h1> التّطبيق الذي لا يفرّق بين التّعلم والمتعة </h1>
              <img id='intro-image' src={intro} />
              <button onClick={() => {
                  const targetElement = document.querySelector('#get-started');
                  const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - 200;
                  window.scrollTo({
                      top: targetPosition,
                      behavior: 'smooth'
                  });
              }}>
                  ابدأ الآن
              </button>
          </div>
          <div className='home-about-us'>
              <h1> ماهو تطبيق الميم والتعليم </h1>
              <div className='description'>
                  <ul>
                    <li> الميم والتعليم هو تطبيق تعليمي موجّه لتلاميذ التّعليم الإعدادي و الثّانوي. </li>
                    <li> يعتمد على الميمز و التّعديلات لإيصال المعلومة بطريقة ممتعة.</li>
                    <li>يعتمد التّطبيق أيضا على المحتوى القصير والتصميم العملي لضمان تجربة استخدام خالية من الملل عند الدراسة.</li>
                    <button> تعرّف أكثر </button>
                  </ul>
                  <div className='ppf'></div>
              </div>
              <img src={creation} />
          </div>
          <div className='availability'>
              <h1> التّطبيق متوفّر الآن على مختلف المنصّات </h1>
              <div className='mobdesk'>
                  <div>
                      <img src={mobile} id='mobile' />
                      <img src={desktop} id='desktop' />
                  </div>
                  <img src={ground} id='ground' />
              </div>
          </div>
          <div className='get-started' id='get-started'>
              <div className='start-mobile'>
                  <h1> تطبيق الهاتف متوفّر على متجر الأندرويد والآيفون </h1>
                  <div className='start-buttons'>
                      <button> 
                          <div className='icon'> <FontAwesomeIcon icon={faApple} size='2xl' /> </div>
                          <div className='text'> Download on the <br></br><span> App Store </span>  </div>
                      </button>
                      <button> 
                          <div className='icon'> <FontAwesomeIcon icon={faGooglePlay} size='2xl' /> </div>
                          <div className='text'> Download on the <span> Google Store </span>  </div>
                      </button>
                  </div>
              </div>
              <div className='start-desktop'>
                  <h1> يمكنك استعمال تطبيق الويب ايضا </h1>
                  <div className='start-buttons'>
                      <button onClick={() => navigate('/authentication/login')}> تسجيل الدخول </button>
                      <button onClick={() => navigate('/authentication/signup')}> إنشاء حساب </button>
                  </div>
              </div>
          </div>
          <div className="objectif">
              <h1>ما هو هدفنا؟</h1>
              <h3>لماذا نقضي ساعات في مواقع التواصل الاجتماعي أو في اللعب و لا نشعر بالوقت؟ لكن عندما
                  نحاول المراجعة، نشعر ببطئ كل دقيقة تمر؟
              </h3>
              <div className="memes">
                <div className="meme">
                  <span>أنا و الزمرة عندما نتفق على قضاء
                        الليلة بأكملها في اللعب عبر الانترنت
                  </span>
                  <img src={meme1} />
                </div>
                <div className="meme">
                  <span>أنا و الزمرة عندما نحاول المراجعة
                        لمدة نصف ساعة
                  </span>
                  <img src={meme2} />
                </div>
              </div>
              <h1 id='our-goal' >هدفنا ببساطة هو جعل المراجعة تجربة
                  ممتعة للتلميذ
              </h1>
          </div>

          <div id="quote">
            <h1>
              "لا تفرق بين العمل واللعب. اعتبر كل ما تفعله بمثابة لعب، ولا تتخيل ولو لدقيقة واحدة أنك يجب أن تكون جاداً"  
            </h1>
            <h1>
              آلان واتس 
            </h1>
          </div>

          <div className="social-media">
            <h1>تابعنا على منصات التواصل الاجتماعي</h1>
            <h3>نقوم بنشر الميمز المتعلقة بالتلاميذ و التعليم</h3>
            <div className="icons">
              <a href="#"><FontAwesomeIcon icon={faInstagram} style={{color: "#F4F5F9",}} /></a>
              <a href="#"><FontAwesomeIcon icon={faFacebookF} style={{color: "#F4F5F9",}} /></a>
              <a href="#"><FontAwesomeIcon icon={faTiktok} style={{color: "#F4F5F9",}} /></a>
              <a href="#"><FontAwesomeIcon icon={faYoutube} style={{color: "#F4F5F9",}} /></a>
            </div>
          </div>
          <Footer />
      </div>
    )
}

export default Home;