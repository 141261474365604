import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok, faYoutube, faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import einstein from '../utils/main_page/einstein.png'
import '../scss/utils/Footer.scss'
const Footer = () =>{
    return(
        <div className="Footer">
            <div className="footer-contact-us">
                <span> تواصل معنا </span>
                <div className="icons">
                    <a href="#"><FontAwesomeIcon icon={faInstagram} style={{color: "#F4F5F9",}} /></a>
                    <a href="#"><FontAwesomeIcon icon={faFacebookF} style={{color: "#F4F5F9",}} /></a>
                    <a href="#"><FontAwesomeIcon icon={faTiktok} style={{color: "#F4F5F9",}} /></a>
                    <a href="#"><FontAwesomeIcon icon={faYoutube} style={{color: "#F4F5F9",}} /></a>
                    <a href="#"><FontAwesomeIcon icon={faEnvelope} style={{color: "#F4F5F9",}} /></a>
                </div>
            </div>
            <div className="footer-main">
                <img src={einstein} alt='einstein' />
                <div className='footer-content'>
                    <span> المزيد </span>
                    <span> تعرّف أكثر </span>
                    <p> جميع الحقوق محفوظة© </p>
                </div>
                <img src={einstein} alt='einstein' />
            </div>
            <div className="footer-apps">
                <span> لا تنسى تحميل التّطبيق </span>
                <div className='footer-buttons'>
                    <button> 
                        <div className='icon'> <FontAwesomeIcon icon={faApple} size='2xl' /> </div>
                        <div className='text'> Download on the <br></br><span> App Store </span>  </div>
                    </button>
                    <button> 
                        <div className='icon'> <FontAwesomeIcon icon={faGooglePlay} size='2xl' /> </div>
                        <div className='text'> Download on the <span> Google Store </span>  </div>
                    </button>
                </div>
                <p> جميع الحقوق محفوظة© </p>
            </div>
        </div>
    )
}

export default Footer;