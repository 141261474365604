import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';
import mainImage from '../../utils/main_page/main.png'
import logo from '../../utils/logo1.png'; 
import canny from '../../utils/mrincredible/canny.png'
import uncanny from '../../utils/mrincredible/uncanny.png'
import superCanny from '../../utils/mrincredible/supercanny.png'
import extremelyCanny from '../../utils/mrincredible/extremelycanny.png'
import finalCanny from '../../utils/mrincredible/finalCanny.png'
import HomeNavbar from "../../hooks/HomeNavbar";
import '../../scss/pages/Home/MobileApp.scss'
import { useSwipeable } from "react-swipeable";  // Import the hook
import { useNavigate } from "react-router-dom";
import Footer from "../../hooks/Footer";

function MobileApp() {
  const navigate = useNavigate()
  const [index, setIndex] = useState(0)
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handlePrev(),
    onSwipedRight: () => handleNext(),
  });

  const handleNext = () => {
    setIndex(prevIndex => (prevIndex === 4 ? 0 : prevIndex + 1));
  };

  const handlePrev = () => {
    setIndex(prevIndex => (prevIndex === 0 ? 4 : prevIndex - 1));
  };
  
  return (
    <div className="MobileApp">
      <HomeNavbar />
      <div className="primary-style">
        <div className="main">
        <div className="main-description">
            <img src={logo} />
            <span>قم بتحميل تطبيق الميم والتعليم</span>
            <h1>التطبيق الذي لا يفرق بين التعلم والمتعة</h1>
            <div className='start-mobile'>
                <button> 
                    <div className='icon'> <FontAwesomeIcon icon={faApple} size='2xl' /> </div>
                    <div className='text'> Download on the <span> App Store </span>  </div>
                </button>
                <button> 
                    <div className='icon'> <FontAwesomeIcon icon={faGooglePlay} size='2xl' /> </div>
                    <div className='text'> Download on the <span> Google Store </span>  </div>
                </button>
            </div>
          </div>
          <div className="image-container">
            <img src={mainImage} />
          </div>
        </div>
      </div>
      <div className="secondary-style">
        <div className="aboutus">
          <h1>ماهو تطبيق الميم والتعليم</h1>
          <div className="description">
            <div className="description-style">
              <img src={uncanny} />
              <span>الميم و التعليم هو تطبيق</span>
              <span>تعليمي موجه لتلاميذ</span>
              <span>التعليم الأساسي و الثانوي</span>
            </div>
            <div className="description-style">
              <img src={canny} />
              <span>يعتمد على الميمز و التعديلات</span>
              <span>لإيصال المعلومة بطريقة</span>
              <span>ممتعة</span>
            </div>
            <div className="description-style">
              <img src={superCanny} />
              <span>يعتمد التّطبيق على المحتوى</span>
              <span>القصير والتّصميم العملي</span>
              <span>لضمان تجربة استخدام خالية</span>
              <span>من الملل عند الدّراسة</span>
            </div>
          </div>
          <div className="conclusion">
            <div className="platform">
                <img src={extremelyCanny} />
                <div>
                  <h3>التطبيق متوفر الآن على
                      متجر الأندرويد والآيفون
                  </h3>
                  <div>
                    <div className='start-mobile'>
                        <button> 
                            <div className='icon'> <FontAwesomeIcon icon={faApple} size='2xl' /> </div>
                            <div className='text'> Download on the <br></br><span> App Store </span>  </div>
                        </button>
                        <button> 
                            <div className='icon'> <FontAwesomeIcon icon={faGooglePlay} size='2xl' /> </div>
                            <div className='text'> Download on the <span> Google Store </span>  </div>
                        </button>
                    </div>
                  </div>
                </div>
            </div>

            <div className="platform">
                <img src={finalCanny} />
                <div>
                  <h3> يمكنك استعمال تطبيق
                  الويب ايضا </h3>
                  <div>
                    <div className='start-desktop'>
                        <button> تسجيل الدخول </button>
                        <button> إنشاء حساب </button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div className="mobile-description">
              <div className="dots">
                  <div className={`dot ${index === 0 ? 'active' : null} `} onClick={() => setIndex(0)}></div>
                  <div className={`dot ${index === 1 ? 'active' : null} `} onClick={() => setIndex(1)}></div>
                  <div className={`dot ${index === 2 ? 'active' : null} `} onClick={() => setIndex(2)} ></div>
                  <div className={`dot ${index === 3 ? 'active' : null} `} onClick={() => setIndex(3)} ></div>
                  <div className={`dot ${index === 4 ? 'active' : null} `} onClick={() => setIndex(4)} ></div>
              </div>
              <div className="design" {...swipeHandlers} >
                  {index === 0 ? (
                      <div className="description-style">
                          <img src={uncanny} />
                          <span>الميم و التعليم هو تطبيق</span>
                          <span>تعليمي موجه لتلاميذ</span>
                          <span>التعليم الأساسي و الثانوي</span>
                      </div>
                  ) : index === 1 ? (
                    <div className="description-style">
                        <img src={canny} />
                        <span>يعتمد على الميمز و التعديلات</span>
                        <span>لإيصال المعلومة بطريقة</span>
                        <span>ممتعة</span>
                    </div>
                  ) : index === 2 ? (
                    <div className="description-style">
                        <img src={superCanny} />
                        <span>يعتمد التّطبيق على المحتوى</span>
                        <span>القصير والتّصميم العملي</span>
                        <span>لضمان تجربة استخدام خالية</span>
                        <span>من الملل عند الدّراسة</span>
                    </div>
                  ) : index === 3 ? (
                    <div className="platform">
                        <img src={extremelyCanny} />
                        <div>
                          <h3>التطبيق متوفر الآن على
                              متجر الأندرويد والآيفون
                          </h3>
                          <div>
                            <div className='start-mobile'>
                                <button> 
                                    <div className='icon'> <FontAwesomeIcon icon={faApple} size='2xl' /> </div>
                                    <div className='text'> Download on the <br></br><span> App Store </span>  </div>
                                </button>
                                <button> 
                                    <div className='icon'> <FontAwesomeIcon icon={faGooglePlay} size='2xl' /> </div>
                                    <div className='text'> Download on the <span> Google Store </span>  </div>
                                </button>
                            </div>
                          </div>
                        </div>
                    </div>
                  ) : (
                    <div className="platform">
                        <img src={finalCanny} />
                        <div>
                          <h3> يمكنك استعمال تطبيق
                          الويب ايضا </h3>
                          <div>
                            <div className='start-desktop'>
                                <button onClick={() => navigate('/authentication/login')}> تسجيل الدخول </button>
                                <button onClick={() => navigate('/authentication/signup')}> إنشاء حساب </button>
                            </div>
                          </div>
                        </div>
                    </div>
                  )}  
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MobileApp;
