import React, {useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import '../scss/utils/Navbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import logo from '../utils/logo1.png'; 
import menu_content from '../utils/icons2/menu_content.png'; 
import ball from '../utils/icons2/ball.png'; 
import book from '../utils/icons2/book.png'; 
import bag from '../utils/icons2/bag.png'; 
import pen from '../utils/icons2/pen.png'; 
import compat from '../utils/icons2/compat.png'; 
import logout_icon from '../utils/logout.png'
import { SERVER } from "../config/config";
import useAuth from '../useAuth';
import axios from 'axios';
import Cookies from 'js-cookie';

const LogoutModal = ({ show, onClose }) => {
    const modalRef = useRef(null);
    const navigate = useNavigate();

    const logout = async () => {
        await axios.post(`${SERVER}/auth/logout`, {}, { withCredentials: true });
        Cookies.remove('token')
        navigate('/')
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };

    useEffect(() => {
        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show]);

    if (!show) {
        return null;
    }

    return (
        <div className="modalLogout-overlay">
            <div className="modal" ref={modalRef}>
                <img src={logout_icon} />
                <h2> هل حقا تريد الخروج </h2>
                <div className='logout-buttons'>
                    <button onClick={logout}> نعم </button>
                    <button onClick={onClose}> لا </button>
                </div>
            </div>
        </div>
    );
};

const Navbar = ({ toggleNavbar, closing  }) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const {user, loading} = useAuth();
    if (user){
        return(
            <div className={`Navbar ${closing ? 'closing' : ''}`}>
                <button id='close-navbar' onClick={toggleNavbar}>
                    <FontAwesomeIcon icon={faXmark} size="2xl" />
                </button>
                <div className='navbar-main'>
                    <a href='/'><img id='logo' src={logo} alt="Logo" /></a>
                    {(user && user.role) === 'admin' ? (
                        <div onClick={(e) => navigate('/subjects')} className='administration'>
                            <img src={menu_content} />
                            <h3>إدارة المحتوى</h3>
                        </div>
                    ) : (user && user.role) !== 'access' ? (
                        <div onClick={(e) => navigate('/subjects')} className='administration'>
                            <img src={menu_content} />
                            <h3>المحتوى</h3>
                        </div>
                    ) : (
                        <></>
                    )}
                    
                    <div className='topics'>
                        {(user && user.role) !== 'access' && (user && user.role) !== 'user' ? (
                            <div className='card'>
                                <img src={compat} />
                                <h4>أدوات</h4>
                            </div>
                        ) : (
                            <></>
                        )}
                        {(user && user.role) !== 'user' && (user && user.role) !== 'enseignant' && (
                            <div className='card'>
                                <img src={ball} />
                                <h4>إدارة الفريق</h4>
                            </div>
                        )}
                        
                        {(user && user.role) === 'user' && (
                            <div className='card' onClick={(e) => navigate('/Profile')} >
                                <img src={book} />
                                <h4>حسابي</h4>
                            </div>
                        )}
                        
                        <div className='card'>
                            <img src={pen} onClick={(e) => navigate('/EditProfile')} />
                            <h4>إعدادات</h4>
                        </div>
                        <div className='card' onClick={() => setShowModal(true)}>
                            <img src={bag} />
                            <h4>خروج</h4>
                        </div>
                        
                    </div>
                </div>
                <LogoutModal show={showModal} onClose={() => setShowModal(false)} />
            </div>
        );
    }
};

export default Navbar;
