import React, { useEffect, useRef, useState } from "react";
import '../scss/pages/Chapters.scss';
import axios from "axios";
import { SERVER } from "../config/config";
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useAuth from '../useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus } from '@fortawesome/free-solid-svg-icons';
import Card from "../hooks/Card";
import NotFound from "../hooks/NotFound";
import Navbar from "../hooks/Navbar";
import { PacmanLoader } from 'react-spinners';

const Chapters = () => {
    localStorage.removeItem('Chapter')
    const {user, loading} = useAuth();
    const navigate = useNavigate();
    const { subjectID } = useParams();

    const [subject, setSubject] = useState(null);
    const [chapters, setChapters] = useState([]);
    const [navbarVisible, setNavbarVisible] = useState(false);
    const [navbarClosing, setNavbarClosing] = useState(false);
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleVideoClick = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };
    useEffect(() => {
        const fetchSubjectData = async () => {
            try {
                const response = await axios.get(`${SERVER}/subjects/getSubject`, {
                    params: {
                        id: subjectID,
                    }
                });
                setSubject(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        const fetchChapters = async () => {
            try {
                const response = await axios.get(`${SERVER}/chapters/chapters`, {
                    params: {
                        subjectID: subject.ID,
                    }
                });
                setChapters(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        if (subjectID){
            fetchSubjectData();
        }

        if (subject){
            fetchChapters();    
        }
    }, [subjectID, subject]);

    const openNavbar = () => {
        setNavbarClosing(false);
        setNavbarVisible(true);
    };

    const closeNavbar = () => {
        setNavbarClosing(true);
        setTimeout(() => {
            setNavbarVisible(false);
        }, 1500);
    };

    const addChapter = () => {
        navigate(`/chapterManagement/${subject.ID}`);
    };

    if (loading) {
        return <div style={{minHeight:'100vh', width:'100%', display: 'flex', justifyContent:'center', alignItems: 'center'}}><PacmanLoader color='#2478DC' /></div>
    }
    
    if (!user) {
        return <Navigate to="/authentication/login" />;
    }else{
        return (
            <div className="Chapters">
                <div className="navigation">
                    { user && user.role !== 'user' && (<button id="add"><FontAwesomeIcon icon={faPlus} onClick={addChapter} size="xl" /></button>) }
                    <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
                </div>
                {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
                <h1> {subject && subject.title} </h1>
                <div className="video-container">
                    <video
                        src={subject && SERVER+subject.intro_video}
                        ref={videoRef}
                        controls={false}
                        onClick={handleVideoClick}
                        id="video-uploaded"
                        autoPlay
                    />
                </div>
    
                <div className={chapters.length >0 ? "main" : "notfound-main"}>
                    <h3> المحاور </h3>
                    <div className="chapters-section">
                        {chapters.length > 0 ? (
                            <div className="chapters-row">
                                {chapters.map((chapter, index) => (
                                    <Card
                                        key={index}
                                        className='chapter-card'
                                        chapter={chapter}
                                        role={user && user.role}
                                    />
                                ))}
                            </div>
                        ) : (
                            <NotFound role={user && user.role} action={addChapter} />
                        )}
                    </div>
                </div>
            </div>
        );
    }
};

export default Chapters;
