import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './scss/App.scss'
import Home from './pages/Home/Home';
import Authentication from './pages/Home/Authentication';
import ChapterManagement from './pages/ChapterManagement';
import Subjects from './pages/Subjects';
import SubjectManagement from './pages/SubjectManagement';
import Chapters from './pages/Chapters';
import Lessons from './pages/Lessons';
import EduMemeManagement from './pages/EduMemeManagement';
import EduMemes from './pages/EduMemes';
import TeamManagement from './pages/administration/TeamManagement';
import ProtectedRoute from './ProtectedRoute';
import NotAllowed from './hooks/NotAllowed';
import EditProfile from './pages/administration/EditProfile';
import ProfileManagement from './pages/administration/ProfileManagement';
import Profile from './pages/administration/Profile';
import MobileApp from './pages/Home/MobileApp';
import ResetPassword from './pages/Home/ResetPassword';
import AboutUs from './pages/Home/AboutUs';
import NotValid from './hooks/NotValid';

function App() {
  // admin apprenant access enseignant
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/DownloadMobileApp' element={<MobileApp />} />
        <Route path='/AboutUs' element={<AboutUs />} />
        <Route path='/authentication/:type' element={<Authentication />} />
        <Route path='/reset-password/:resetToken' element={<ResetPassword />} />
        <Route path='/subjects' element={<Subjects />} />
        <Route path='/chapters/:subjectID' element={<Chapters />} />
        <Route path='/lessons/:subjectID/:chapterID' element={<Lessons />} />
        <Route path='/edumemes/:lessonID' element={<EduMemes />} />
        <Route path='/Profile' element={<Profile />} />
        <Route path='/EditProfile' element={<EditProfile />} />
        <Route path='/ProfileManagement/:type/:user' element={<ProfileManagement />} />

        <Route path='/subjectManagement' element={<ProtectedRoute element={<SubjectManagement />} allowedRoles={['admin', 'enseignant']} />} />
        <Route path='/subjectManagement/:subjectEdit' element={<ProtectedRoute element={<SubjectManagement />} allowedRoles={['admin', 'enseignant']} />} />
        <Route path='/chapterManagement/:subjectID' element={<ProtectedRoute element={<ChapterManagement />} allowedRoles={['admin', 'enseignant']} />} />
        <Route path='/edumememanagement/:lessonID/:edumemeID' element={<ProtectedRoute element={<EduMemeManagement />} allowedRoles={['admin', 'enseignant']} />} />
        <Route path='/chapterManagement/:subjectID/:chapterID' element={<ProtectedRoute element={<ChapterManagement />} allowedRoles={['admin', 'enseignant']} />} />
        <Route path='/edumememanagement/:lessonID' element={<ProtectedRoute element={<EduMemeManagement />} allowedRoles={['admin', 'enseignant']} />} />
        <Route path='/TeamManagement' element={<ProtectedRoute element={<TeamManagement />} allowedRoles={['admin', 'access']} />} />


        <Route path='/not-allowed' element={<NotAllowed />} />
        <Route path='/TokenNotValid' element= {<NotValid />} />
      </Routes>
    </Router>
  );
}

export default App;
