import React, { useEffect, useState, useRef } from "react";
import '../scss/pages/Subjects.scss';
import axios from "axios";
import { SERVER } from "../config/config";
import { Navigate, useNavigate } from 'react-router-dom';
import useAuth from '../useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons';
import Card from "../hooks/Card";
import NotFound from "../hooks/NotFound";
import Navbar from '../hooks/Navbar';
import { PacmanLoader } from 'react-spinners';
import Info from "../hooks/Info";

const Subjects = () => {
    localStorage.removeItem('Subject')
    const navigate = useNavigate();
    const {user, loading} = useAuth();
    const [subjects, setSubjects] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [navbarVisible, setNavbarVisible] = useState(false);
    const [navbarClosing, setNavbarClosing] = useState(false);
    const dropdownRef = useRef(null);
    const [first_login, setFirstLogin] = useState(Boolean(localStorage.getItem('first_login')))

    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const response = await axios.get(`${SERVER}/subjects/subjects`);
                setSubjects(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchSubjects();

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    const addSubject = () => {
        navigate('/subjectManagement');
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const openNavbar = () => {
        setNavbarClosing(false);
        setNavbarVisible(true);
    };

    const closeNavbar = () => {
        setNavbarClosing(true);
        setTimeout(() => {
            setNavbarVisible(false);
        }, 500);
    };

    const setAccept = () =>{
        localStorage.removeItem('first_login')
        setFirstLogin(false)
    }

    if (loading) {
        return <div style={{minHeight:'100vh', width:'100%', display: 'flex', justifyContent:'center', alignItems: 'center'}}><PacmanLoader color='#2478DC' /></div>
    }
    if(!user){
        return <Navigate to="/authentication/login" />;
    }else{
        return (
            <div className="subjects">
                        
                {first_login && (
                <>
                    <div className="modal-overlay" />
                    <Info setAccept={setAccept} />
                </>)}
                <div className="navigation">
                    { user && user.role !== 'user' && <button id="add"><FontAwesomeIcon icon={faPlus} onClick={addSubject} size="xl" /></button>}
                    <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
                </div>
                {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
                <div className="main">
                    <h1>المواد</h1>
                    {/* <div className="levels" onClick={toggleDropdown}>
                        <span>المستوى: {localStorage.getItem('level') || 'اختر المستوى'}</span>
                        <button onClick={toggleDropdown}><FontAwesomeIcon icon={faFilter} /></button>
                        {dropdownVisible && (
                            <div ref={dropdownRef} className="dropdown">
                            </div>
                        )}
                    </div>*/}
                    <span> المستوى : التاسعة أساسي </span>
                    <div className="subjects-section">
                        {subjects.length > 0 ? (
                            subjects.map((subject, index) => (
                                <Card
                                    key={index}
                                    className='subject-card'
                                    subject={subject}
                                    role={user && user.role}
                                />
                            ))
                        ) : (
                            <NotFound role={user && user.role} action={addSubject} />
                        )}
                    </div>
                </div>
            </div>
        );
    }
};

export default Subjects;
