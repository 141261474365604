import HomeNavbar from "../../hooks/HomeNavbar";
import logo from '../../utils/logo1.png'; 
import '../../scss/pages/Home/AboutUs.scss'
import about1 from '../../utils/about1.png'
import about2 from '../../utils/about2.png'
import about3 from '../../utils/about3.png'
import intro from '../../utils/intro.mp4'
import Footer from "../../hooks/Footer";

const AboutUs = () => { 
    return(
        <div className='AboutUs'>
            <HomeNavbar />
            <div className='intro'>
              <img src={logo} />
              <h1> الميم والتعليم هو تطبيق تعليمي يساعد تلاميذ التّعليم الإعدادي و الثّانوي على مراجعة دروسهم. </h1>
            </div>
            <h1> الأدوات و التّقنيات التي يعتمد عليها الميم و التعليم </h1>
            <div className="about">
                <div className="text">
                    <span> الميمز: </span>
                    <p> الميمز (memes) هي صور أو فيديوهات قصيرة تتضمّن تعليقات أو مواقف فكاهيّة، يتمّ تداولها بشكل واسع عبر الإنترنت ووسائل التّواصل الاجتماعي.
                    غالبًا ما تحتوي هذه الميمز على نص قصير مرفق بصورة أو فيديو يعبّر عن حالة أو موقف معيّن بطريقة طريفة. </p>
                </div>
                <div className="meme">
                    <img src={about1} alt="meme1" />
                </div>
            </div>
            <div className="about">
                <div className="meme">
                    <video src={intro} alt="intro_video" controls />
                </div>
                <div className="text">
                    <span> التّعديلات: </span>
                    <p> التّعديلات (Edits) هي فيديوهات يتمّ تحسينها بإضافة تأثيرات جميلة مثل تغييرات سريعة في اللّقطات، موسيقى شهيرة، مزامنة الحركات مع الإيقاع، نصوص و رسومات. الهدف هو جعل الفيديو أكثر تفاعلاً وترفيهًا. </p>
                </div>
            </div>
            <div className="about">
                <div className="text">
                    <span> المحتوى القصير: </span>
                    <p> الدّرس في الميم والتعليم يكون مقسّمًا إلى سلسلة من المعلومات القصيرة والمبسّطة، مصحوبة بمجموعة من الميمز والتّعديلات لجعلها ممتعة أكثر. الهدف هو تسهيل الفهم وتعزيز الاستيعاب بطريقة مرحة وإبداعية. </p>
                    <span> التصميم: </span>
                    <p> يهدف التّصميم العملي للميم و التعليم إلى ضمان تجربة تعليمية مشوّقة وخالية من الملل، مستوحاة من تصاميم منصّات التّواصل الاجتماعي، حيث يتمكّن التلميذ من التّفاعل مع محتوى التطبيق, مثلا بوضع إعجاب أو إضافة تعليق. </p>
                </div>
                <div className="meme">
                    <img src={about2} alt="meme1" />
                </div>
            </div>
            <div className="about">
                <div className="meme">
                    <img src={about3} alt="meme1" />
                    <span> مثال لإعتماد أسلوب التّرسيخ في درس بنية الجهاز العصبي </span>
                </div>
                <div className="text">
                    <span> أسلوب التّرسيخ: </span>
                    <p> أسلوب التّرسيخ يعتمد على ربط المعلومات بفكرة أو شخصيّة محدّدة لضمان بقائها راسخة في الذّهن. من خلال دمج المحتوى التّعليمي مع عناصر مألوفة أو مثيرة للاهتمام، يصبح من الأسهل استيعاب  المعلومات وتذكرها. </p>
                </div>
            </div>
            <div className="main-goal">
                <span> الهدف </span>
                <p> هدف مشروع "الميم والتعليم" هو تحسين وتطوير تجربة التّعلم من خلال أسلوب مرح وممتع و ذلك باستخدام الميمز و التعديلات. </p>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs;