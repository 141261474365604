import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import '../scss/utils/HomeNavbar.scss'
import useAuth from '../useAuth';
import Navbar from './Navbar';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { SERVER } from '../config/config';
import Cookies from 'js-cookie';

const HomeNavbar = () =>{
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate()
    const { user, loading } = useAuth();
    const [navbarVisible, setNavbarVisible] = useState(false);
    const [navbarClosing, setNavbarClosing] = useState(false);
    const location = useLocation(); // Get the current location
    const [mobileHomeNavbar, setMobileHomeNavbar] = useState(false)
    useEffect(() =>{
      if (user){
        setIsAuthenticated(true)
      }
    }, [user])
    
    const openNavbar = () => {
        setNavbarClosing(false);
        setNavbarVisible(true);
    };
  
    const closeNavbar = () => {
        setNavbarClosing(true);
        setTimeout(() => {
            setNavbarVisible(false);
        }, 500);
    };

    const logout = async () => {
        await axios.post(`${SERVER}/auth/logout`, {}, { withCredentials: true });
        Cookies.remove('token')
        if (location.pathname === '/'){
            window.location.reload()
        }else{
            navigate('/')
        }
    };
    
    return(
        <>  
            <div className='HomeNavbar'>
                {
                    isAuthenticated && location.pathname !== '/' && location.pathname !== '/DownloadMobileApp' && location.pathname !== '/AboutUs' ? (
                    <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
                    ) : (<></>)
                }
                {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
                <span className='logo'> الميم و التعليم </span>
                <NavLink to='/' activeclassname='active'> الرّئيسيّة </NavLink>
                <NavLink to='/DownloadMobileApp' activeclassname='active'> تطبيق الهاتف </NavLink>
                <NavLink to='/AboutUs' activeclassname='active'> تعرّف أكثر </NavLink>
                {isAuthenticated && <NavLink to='/subjects' activeclassname='active' style={{textDecoration :'underline'}}> تصفح المحتوى </NavLink>}
                {isAuthenticated ? (
                    <>
                        <NavLink to='/Profile' activeclassname='active'> حسابي </NavLink>
                        <button onClick={logout}> تسجيل خروج </button>
                    </>
                ) : (
                    <NavLink to='/authentication/login' activeclassname='active'> تسجيل الدخول </NavLink>
                )}
            </div>

            <div className='HomeNavbar-mobile'>
                <button id='navbar-click' onClick={() => setMobileHomeNavbar(!mobileHomeNavbar)}> <FontAwesomeIcon icon={!mobileHomeNavbar ? faBars : faX} /> </button>
                <div className={mobileHomeNavbar ? 'HomeNavbar-mobile-main show' : 'HomeNavbar'}>
                    <div>
                        <NavLink to='/' activeclassname='active'> الرّئيسيّة </NavLink>
                        <NavLink to='/DownloadMobileApp' activeclassname='active'> تطبيق الهاتف </NavLink>
                        <NavLink to='/AboutUs' activeclassname='active'> تعرّف أكثر </NavLink>
                        {isAuthenticated && <NavLink to='/subjects' activeclassname='active' style={{textDecoration :'underline'}}> تصفح المحتوى </NavLink>}
                        {isAuthenticated ? (
                            <div className='auth-buttons'>
                                <NavLink to='/Profile' activeclassname='active'> حسابي </NavLink>
                                <button onClick={logout}> تسجيل خروج </button>
                            </div>
                        ) : (
                            <div className='auth-buttons'>
                                <NavLink to='/authentication/login' activeclassname='active'> تسجيل الدخول </NavLink>
                                <NavLink id='create-account' to='/authentication/signup' activeclassname='active'> إنشاء حساب </NavLink>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeNavbar;